<template lang="pug">
div
  Breadcrumbs(:breadcrumbs="breadcrumbs")
  TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
  :itemRowBackground="itemRowBackground"
)
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Breadcrumbs: () => import('@/components/atoms/Breadcrumbs')
  },
  data () {
    return {
      headers: [
        { value: 'sailorFullName', text: this.$t('sailor'), title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'page_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortabl: false }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.goToSailor',
          to: (item) => ({ name: 'sailor', params: { id: item.sailor.id } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-account-circle-outline'
        }
      ],
      breadcrumbs: {
        historyBreadcrumbs: [
          {
            text: this.$t('atc'),
            link: { name: 'atc-distribution-institution-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'atc-distribution-institution-report'
          },
          {
            text: this.$t('qualification'),
            link: { name: 'atc-distribution-qualification-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'atc-distribution-qualification-report'
          },
          {
            text: this.$t('sailor'),
            link: { name: 'atc-distribution-sailor-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'atc-distribution-sailor-report'
          }
        ],
        checkAccess: () => true
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.advanceTrainingDistribution,
      isLoading: state => state.report.isLoadingReport
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'advanceTrainingDistribution', access: checkAccess('distribution-report') })
  },
  methods: {
    ...mapActions(['getListAdvanceTrainingSailorDistribution']),

    async getData (params) {
      await this.getListAdvanceTrainingSailorDistribution({ params })
    },
    itemRowBackground (item) {
      return item.form2_sum < 0 ? 'red-table-row' : ''
    }
  }
}

</script>
